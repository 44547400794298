/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-input-rename */
import { Component, Input, OnInit } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { ProgressBarService } from 'src/app/services/useful/loading/progress-bar/progress-bar.service';
import { ToastService } from 'src/app/services/useful/toast/toast.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-irpf',
  templateUrl: './modal-irpf.page.html',
  styleUrls: ['./modal-irpf.page.scss'],
})
export class ModalIrpfPage implements OnInit {
  @Input('irpf') irpf;
  private logo: any = environment.logoSlogan;
  private url: any;

  constructor(
    public modalController: ModalController,
    private progressBar: ProgressBarService,
    private platform: Platform,
    private iab: InAppBrowser,
    private toast: ToastService
  ) { }

  ngOnInit() { }

  GoClose() {
    this.modalController.dismiss({});
  }

  ChangeValue(val) {
    this.url = val;
    console.log(this.url);
  }

  CheckPlatform() {
    if (this.platform.is('android') || this.platform.is('ios')) {
      return 'mobile'
    }
    else {
      return 'web'
    }
  }

  async Download() {
    if (this.url) {
      if (this.platform.is('android')) {
        const browser = await this.iab.create(
          this.url,
          '_system',
          'location=yes,closebuttoncolor=#ffffff,hidenavigationbuttons=yes,hideurlbar=yes,Navigationbuttoncolor=#3c6070,toolbarcolor=#3c6070'
        );
        browser.show();
        this.GoClose();
      } else if (this.platform.is('ios')) {
        const browser = await this.iab.create(
          this.url,
          '_system',
          'location=yes,closebuttoncolor=#ffffff,Navigationbuttoncolor=#3c6070,hidenavigationbuttons=yes,toolbarcolor=#3c6070,toolbartranslucent=yes,toolbarposition=top'
        );
        browser.show();
        this.GoClose();
      } else {
        if (!window.ActiveXObject) {
          const save = document.createElement('a');
          save.href = this.url;
          save.target = '_blank';
          save.download = 'IR' || 'unknown';

          const evt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          save.dispatchEvent(evt);

          (window.URL || window.webkitURL).revokeObjectURL(save.href);
          this.GoClose();
        }

        // for IE < 11
        else if (!!window.ActiveXObject && document.execCommand) {
          const wwindow = window.open(this.url, '_blank');
          wwindow.document.close();
          wwindow.document.execCommand('SaveAs', true, 'IR' || this.url);
          wwindow.close();
          this.GoClose();
        }
      }
    } else {
      const toast = {
        message: 'Selecione uma data para fazer download do seu IR.',
        color: 'easy-blue-primary',
      };
      this.toast.presentToast(toast);
    }
  }

  async WhatsApp() {
    if (this.url) {
      if (this.platform.is('android')) {
        const browser = await this.iab.create(
          `https://api.whatsapp.com/send?text=Acesse o IR: - ${this.url}`,
          '_system',
          'location=yes,closebuttoncolor=#ffffff,hidenavigationbuttons=yes,hideurlbar=yes,Navigationbuttoncolor=#3c6070,toolbarcolor=#3c6070'
        );
        browser.show();
        this.GoClose();
      } else if (this.platform.is('ios')) {
        const browser = await this.iab.create(
          `https://api.whatsapp.com/send?text=Acesse o IR: - ${this.url}`,
          '_system',
          'location=yes,closebuttoncolor=#ffffff,Navigationbuttoncolor=#3c6070,hidenavigationbuttons=yes,toolbarcolor=#3c6070,toolbartranslucent=yes,toolbarposition=top'
        );
        browser.show();
        this.GoClose();
      } else {
        window.open(
          `https://api.whatsapp.com/send?text=Acesse o IR: - ${this.url}`,
          '_blank'
        );
      }
    } else {
      const toast = {
        message: 'Selecione uma data para enviar seu IR pelo WhatsApp.',
        color: 'easy-blue-primary',
      };
      this.toast.presentToast(toast);
    }
  }
}
