import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarService {
  constructor() {}

  public startProgressBar() {
    $('body')
      .append(
        `
        <ion-backdrop id="backdrop" visible="false" tappable="false" stopPropagation="false"></ion-backdrop>
        <ion-progress-bar id="progress-bar" type="indeterminate" color="easy-blue-primary"></ion-progress-bar>
      `
      )
      .show('slow');
  }

  public stopProgressBar() {
    $('body #progress-bar').remove();
    $('body #backdrop').remove();
  }
}
