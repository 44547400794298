/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpHeaderResponse,
} from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of, throwError, observable } from 'rxjs';
import * as moment from 'moment';

import { User } from 'src/app/auth/user';
import { AuthResponse } from 'src/app/auth/authResponse';
import { ToastService } from 'src/app/services/useful/toast/toast.service';
import { environment } from 'src/environments/environment';
// import { getAuth, signOut } from 'firebase/auth';
import firebase from 'firebase/app';
import { ProgressBarService } from '../../useful/loading/progress-bar/progress-bar.service';
import { FirebaseAuthentication } from '@ionic-native/firebase-authentication/ngx';
import CryptoJS from 'crypto-js'

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  authenticationState = new BehaviorSubject(true);
  authenticationStateUser = new BehaviorSubject(false);

  constructor(
    private plt: Platform,
    private httpClient: HttpClient,
    private toast: ToastService,
    private progressBar: ProgressBarService,
    private firebaseAuthentication: FirebaseAuthentication
  ) {
    this.plt.ready().then(() => {
      this.CheckToken();
    });
  }

  CheckToken() {
    if (environment.storage.getItem('USER')) {
      let user = JSON.parse(environment.storage.getItem('USER'));
      switch (user.sucesso) {
        case true:
          this.authenticationStateUser.next(true);
          this.authenticationState.next(false);
          break;
        default:
          this.authenticationStateUser.next(false);
          this.authenticationState.next(true);
      }
    }
  }

  CreateToken() {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-key': environment.KEY,
      }),
    };

    return this.httpClient
      .get(
        `${environment.apiBaseEasyPlan}/baseapis/create/token`,
        httpOptions
      )
      .pipe(tap(async (res: any) => {
      }));
  }

  IsAuthenticated() {
    // return this.authenticationState.asObservable();
    return this.authenticationState.value;
  }

  IsUserAuthenticated() {
    return this.authenticationStateUser.value;
  }

  Login(user): Observable<AuthResponse> {
    let formData = new FormData();
    let url;
    if (user.cpf.replace(/\D/g, '').length === 11) {
      formData.append('cpf', user.cpf.replace(/\D/g, ''));
      formData.append('dataNascimento', user.dataNascimento);
      url =
        'https://administradora.easyplan.com.br/AreaBeneficiario/Autenticar';
    } else {
      formData.append('cnpj', user.cpf.replace(/\D/g, ''));
      formData.append('apolice', user.apolice);
      url = 'https://administradora.easyplan.com.br/AreaEmpresa/Autenticar';
    }

    const httpOptions = {
      withCredentials: true,
    };

    return this.httpClient
      .post(`${url}`, formData, { headers: {}, withCredentials: true })
      .pipe(tap(async (res: any) => { }));
  }

  LoginBaseEasy(user) {
    let ACCESSTOKENBASE = JSON.parse(environment.storage.getItem('ACCESSTOKENBASE'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': ACCESSTOKENBASE,
      }),
    };

    return this.httpClient
      .post(
        `${environment.apiBaseEasyPlanClientes}/cliente/auth/signin`,
        { documento: user.cpf.replace(/\D/g, ''), password: user.password },
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  AddUserBaseEasy(user) {
    let ACCESSTOKENBASE = JSON.parse(environment.storage.getItem('ACCESSTOKENBASE'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': ACCESSTOKENBASE,
      }),
    };

    return this.httpClient
      .post(
        `${environment.apiBaseEasyPlanClientes}/cliente/user`,
        user,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetUserBaseEasy(user) {
    let ACCESSTOKENBASE = JSON.parse(environment.storage.getItem('ACCESSTOKENBASE'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': ACCESSTOKENBASE,
      }),
    };

    return this.httpClient
      .get(
        `${environment.apiBaseEasyPlanClientes}/cliente/user/${user.cpf.replace(/\D/g, '')}`,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  SendCodeWhatsappBaseEasy(dados) {
    let ACCESSTOKENBASE = JSON.parse(environment.storage.getItem('ACCESSTOKENBASE'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': ACCESSTOKENBASE,
      }),
    };

    return this.httpClient
      .post(
        `${environment.apiBaseEasyPlanClientes}/cliente/twofactorauthentication/sendcode`,
        // `https://localhost:3092/cliente/twofactorauthentication/sendcode`,
        dados,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  VerifyCodeWhatsappBaseEasy(dados) {
    let ACCESSTOKENBASE = JSON.parse(environment.storage.getItem('ACCESSTOKENBASE'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': ACCESSTOKENBASE,
      }),
    };

    return this.httpClient
      .post(
        `${environment.apiBaseEasyPlanClientes}/cliente/twofactorauthentication/verifycode`,
        // `https://localhost:3092/cliente/twofactorauthentication/verifycode`,
        dados,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  PasswordRecoveryBaseEasy(user) {
    let ACCESSTOKENBASE = JSON.parse(environment.storage.getItem('ACCESSTOKENBASE'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': ACCESSTOKENBASE,
      }),
    };

    return this.httpClient
      .put(`${environment.apiBaseEasyPlanClientes}/cliente/user/password/${user.documento}`, { password: user.password }, httpOptions)
      .pipe(
        tap(async (res: any) => { })
      );
  }

  GetContratoDigitalSaudePorCPF(dados) {
    let ACCESSTOKENBASE = JSON.parse(environment.storage.getItem('ACCESSTOKENBASE'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': ACCESSTOKENBASE,
      }),
    };

    return this.httpClient
      .get(
        `${environment.apiBaseEasyPlan}/digitalsaude/contrato/procurarPorCpfTitular/${dados.cpf}`,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetContratoDigitalSaudePorCPFEmail(dados) {
    let ACCESSTOKENBASE = JSON.parse(environment.storage.getItem('ACCESSTOKENBASE'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': ACCESSTOKENBASE,
      }),
    };

    return this.httpClient
      .post(
        `${environment.apiBaseEasyPlan}/digitalsaude/contrato/procurarPorClientePortal`,
        {
          cpf: dados.cpf,
          email: dados.email,
        },
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  PostDevice(device) {
    let user = JSON.parse(environment.storage.getItem('USER'));
    let formData = new FormData();
    formData.append('token', device.token);
    formData.append('tipo', device.tipo);
    const httpOptions = {
      headers: new HttpHeaders({
        'x-token': user.token
          ? user.token
          : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
        'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
        'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
      }),
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/UsuarioToken/Salvar`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  Logout() {
    this.progressBar.startProgressBar();
    let user = JSON.parse(environment.storage.getItem('USER'));
    // if (this.plt.is('ios')) {
    //   this.firebaseAuthentication
    //     .signOut()
    //     .then(() => {
    //       environment.storage.removeItem('USER');
    //       this.authenticationState.next(true);
    //       this.authenticationStateUser.next(false);
    //     })
    //     .catch((error) => {
    //       // An error happened.
    //     });
    // } else {
    //   // const auth = getAuth();
    //   firebase
    //     .auth()
    //     .signOut()
    //     .then(() => {
    //       environment.storage.removeItem('USER');
    //       this.authenticationState.next(true);
    //       this.authenticationStateUser.next(false);
    //     })
    //     .catch((error) => {
    //       // An error happened.
    //     });
    // }
    environment.storage.removeItem('USER');
    this.authenticationState.next(true);
    this.authenticationStateUser.next(false);

    this.progressBar.stopProgressBar();
  }

  // PasswordRecovery(email): Observable<any> {
  //   return this.httpClient
  //     .post(`${AUTH_SERVER_ADDRESS}/auth/password_recovery`, email, httpOptions)
  //     .pipe(tap(async (res: any) => {}));
  // }

  // PasswordChange(user: User): Observable<AuthResponse> {
  //   return this.httpClient
  //     .post(`${AUTH_SERVER_ADDRESS}/auth/password_change`, user, httpOptions)
  //     .pipe(
  //       tap(async (res: AuthResponse) => {
  //         if (res.user) {
  //           await environment.storage.setItem(
  //             'ACCESSTOKEN',
  //             JSON.stringify(res.acessToken)
  //           );
  //           await environment.storage.setItem(
  //             'EXPIRESAT',
  //             JSON.stringify(res.expiresAt)
  //           );
  //           await environment.storage.setItem('USER', JSON.stringify(res.user));
  //           switch (res.user.role) {
  //             case 'USER':
  //               this.authenticationStateUser.next(true);
  //               this.authenticationState.next(false);
  //               break;
  //             case 'ADMIN':
  //               this.authenticationStateUser.next(true);
  //               this.authenticationState.next(false);
  //               break;
  //             default:
  //               this.authenticationStateUser.next(false);
  //               this.authenticationState.next(true);
  //           }
  //         }
  //       })
  //     );
  // }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('Um erro ocorreu:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Código retornado pelo backend ${error.status}, ` +
      //   `corpo era: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Algo de errado aconteceu; Por favor, tente novamente mais tarde!'
    );
  }

  criptografar(password) {
    let cipherPassword = CryptoJS.AES.encrypt(password, 'GjNn|%tQ_3i-n2FKwA>q2NK@L3£!{K9m>£^>3c;9\]}@6EHyu%').toString();
    return cipherPassword
  }
}
