import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.page.html',
  styleUrls: ['./modal-alert.page.scss'],
})
export class ModalAlertPage implements OnInit {
  @Input('communication') communication;
  private logo: any = environment.logoSlogan;

  constructor(public modalController: ModalController,) { }

  ngOnInit() {
  }

  Confirm() {
    this.modalController.dismiss({});
  }

}
