import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(public toastController: ToastController) {}

  async presentToast(value) {
    const toast = await this.toastController.create({
      message: value.message,
      color: value.color,
      duration: value.time ? value.time : 2500,
      position: 'top',
      cssClass: value.css ? value.css : 'toast',
    });
    toast.present();
  }

  async presentToastWithOptions(value) {
    const toast = await this.toastController.create({
      message: value.message,
      color: value.color,
      duration: value.duration ? value.duration : 2500,
      position: 'top',
      cssClass: 'toast',
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }

  async presentToastCopy() {
    const toast = await this.toastController.create({
      message: 'Código de barras copiado com sucesso!!!',
      color: 'easy-blue-primary',
      duration: 1000,
      position: 'top',
      cssClass: 'toast',
    });
    toast.present();
  }
}
